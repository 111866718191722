// ==================================================
//  モバイル判定イベント
// ==================================================
function is_mobile() {
  const intFrameWidth = window.innerWidth;
  return intFrameWidth <= 767 ? true : false;
}

// ==================================================
//  Header
// ==================================================
// ドロップダウンメニュー
const headerMenuDropdownButton = document.querySelector(".js-sp-headerDropdownButton");
const headerMenuDropdownButtonActiveClass = "js-sp-headerDropdownButton-active";
function headerMenuDropdownToggle() {
  headerMenuDropdownButton.classList.toggle(headerMenuDropdownButtonActiveClass);
}
headerMenuDropdownButton.addEventListener("click", (e) => {
  if (is_mobile()) {
    e.preventDefault();
    headerMenuDropdownToggle();
    return false;
  }
});

// ==================================================
//  Footer
// ==================================================
// ドロップダウンメニュー
const footerMenuDropdownButton = document.querySelector(".js-sp-footerDropdownButton");
const footerMenuDropdownButtonActiveClass = "js-sp-footerDropdownButton-active";
function footerMenuDropdownToggle() {
  footerMenuDropdownButton.classList.toggle(footerMenuDropdownButtonActiveClass);
}
footerMenuDropdownButton.addEventListener("click", (e) => {
  if (is_mobile()) {
    e.preventDefault();
    footerMenuDropdownToggle();
  }
});

// ロゴアニメーション
let once = false;
const footerLogoElms = document.querySelector(".js-footerLogo");
window.addEventListener("scroll", function () {
  let taeget_position = footerLogoElms.getBoundingClientRect().top + 300;
  if (taeget_position <= window.innerHeight && once !== true) {
    once = true;
    footerLogoElms.classList.add("js-footerLogo-active");
  }
});

// ==================================================
//  ヘッダーメニューボタン（モバイル）
// ==================================================
const menu = document.querySelector(".js-headerMenu");
const menuActiveClass = "js-headerMenu-active";
const menuButton = document.querySelector(".js-headerMenuButton");
const menuButtonActiveClass = "js-headerMenuButton-active";
const telButton = document.querySelector(".header_telButton");
const telButtonActiveClass = "header_telButton-w";
const instagramButton = document.querySelector(".header_instagramButton");
const instagramButtonActiveClass = "header_instagramButton-w";
function headerMenuToggle() {
  menu.classList.toggle(menuActiveClass);
  menuButton.classList.toggle(menuButtonActiveClass);
  telButton.classList.toggle(telButtonActiveClass);
  instagramButton.classList.toggle(instagramButtonActiveClass);
}
menuButton.addEventListener("click", (event) => {
  headerMenuToggle();
});

// ==================================================
//  アンカーリンクのスムーススクロール
// ==================================================
const anchors = document.querySelectorAll('a[href^="#"]');
const headerHeight = document.querySelector("header").offsetHeight;
const urlHash = location.hash;

for (let i = 0; i < anchors.length; i++) {
  anchors[i].addEventListener("click", (e) => {
    e.preventDefault();

    /* ==================================================
     * ヘッダーメニューが開いていたら閉じる
     */
    if (menu.classList.contains(menuActiveClass)) {
      if (is_mobile() && anchors[i].classList.contains("js-sp-headerDropdownButton")) {
        return false;
      } else {
        headerMenuToggle();
      }
    }
    /* ================================================== */

    const href = anchors[i].getAttribute("href");
    if (
      (is_mobile() && anchors[i].classList.contains("js-sp-footerDropdownButton")) ||
      (is_mobile() && anchors[i].classList.contains("js-sp-headerDropdownButton"))
    ) {
      return false;
    } else {
      if (href !== "#top" && href !== "#") {
        const target = document.getElementById(href.replace("#", ""));
        const position = window.scrollY + target.getBoundingClientRect().top - headerHeight;
        window.scroll({
          top: position,
          behavior: "smooth",
        });
      } else {
        window.scroll({
          top: 0,
          behavior: "smooth",
        });
      }
    }
  });
}

// ==================================================
//  アンカーリンクのページ遷移位置調整
// ==================================================
window.addEventListener("load", (event) => {
  const url = location.href;
  const headerHeight = document.querySelector("header").offsetHeight;

  if (url.indexOf("#") != -1) {
    const anchor = url.split("#");
    const target = $("#" + anchor[anchor.length - 1]);
    const position = Math.floor(target.offset().top) - headerHeight;
    window.scroll({
      top: position,
      behavior: "auto",
    });
  }
});

// ==================================================
//  追従要素の横スクロール連動
// ==================================================
const fixedScroll = document.querySelector(".js-fixedScroll");
window.addEventListener("scroll", () => {
  fixedScroll.style.left = -window.scrollX + "px";
});
